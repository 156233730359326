import { useEffect, useState } from 'react'

import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Divider,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

import { LoginActions, useLogin } from './LoginContext'
import authErrorToComponent from './authErrorToComponent'

const dialogStates = Object.freeze({
  SIGN_IN: Symbol('SIGN_IN'),
  SIGN_UP: Symbol('SIGN_UP'),
  FORGOT_PASSWORD: Symbol('FORGOT_PASSWORD'),
})

const isUnknownState = state => !Object.values(dialogStates).includes(state)

const SignInSignUp = ({ setWindowTitle, loginPending, setLoginPending }) => {
  const [signInSignUp, setSignInSignUp] = useState(dialogStates.SIGN_IN)
  const [loginError, setLoginError] = useState('')
  const [loginErrorOpen, setLoginErrorOpen] = useState(false)
  const [passwordHelpOpen, setPasswordHelpOpen] = useState(false)
  const [passwordResetSentOpen, setPasswordResetSentOpen] = useState(false)

  useEffect(() => {
    if (loginError) {
      setLoginErrorOpen(true)
    }
  }, [loginError])

  useEffect(() => {
    if (isUnknownState(signInSignUp)) {
      // TODO what to do if unknown state?
      console.warn('Unknown sign in state!')
    }
  }, [signInSignUp])

  return (
    <>
      {!loginPending && signInSignUp === dialogStates.SIGN_IN &&
        <SignIn setSignInSignUp={setSignInSignUp} setLoginError={setLoginError} setWindowTitle={setWindowTitle} setLoginPending={setLoginPending} />}
      {!loginPending && signInSignUp === dialogStates.SIGN_UP &&
        <SignUp setSignInSignUp={setSignInSignUp} setLoginError={setLoginError} setPasswordHelpOpen={setPasswordHelpOpen} setWindowTitle={setWindowTitle} setLoginPending={setLoginPending} />}
      {!loginPending && signInSignUp === dialogStates.FORGOT_PASSWORD &&
        <ForgotPassword setSignInSignUp={setSignInSignUp} setLoginError={setLoginError} setWindowTitle={setWindowTitle} setPasswordResetSentOpen={setPasswordResetSentOpen} />}
      {loginPending &&
        <CircularProgress sx={{ margin: 8 }} />}
      {isUnknownState(signInSignUp) &&
        <UnknownErrorDialog />}
      <LoginErrorDialog open={loginErrorOpen} setOpen={setLoginErrorOpen} status={loginError} setLoginPending={setLoginPending} />
      <PasswordHelpDialog open={passwordHelpOpen} setOpen={setPasswordHelpOpen} />
      <PasswordResetSentDialog open={passwordResetSentOpen} setOpen={setPasswordResetSentOpen} setSignInSignUp={setSignInSignUp} />
    </>
  )
}

const UnknownErrorDialog = () => {
  return (
    <Dialog open={true}>
      <DialogTitle variant='h2'>Unknown Error</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Refresh the page and try again.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

const EmailTextField = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)

  useEffect(() => {
    if (email?.length > 2 && !isQualifiedEmail(email)) {
      setEmailError('Please enter a valid email address')
    } else {
      setEmailError(null)
    }
  }, [email])

  return (
    <TextField
      margin='normal'
      required
      fullWidth
      id='email'
      name='email'
      label='Email Address'
      type='email'
      autoComplete='email'
      autoFocus
      onChange={event => setEmail(event.target.value)}
      error={!!emailError}
      helperText={emailError}
    />
  )
}

const GoogleLoginButton = ({ setGoogleError, signUp, setLoginPending }) => {
  const [, loginDispatch] = useLogin()

  return (
    <Box>
      <Button
        variant='outlined'
        fullWidth
        sx={{
          mt: 2,
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
        onClick={async () => {
          try {
            setLoginPending(true)
            await loginDispatch({ type: LoginActions.GOOGLE_LOGIN })
            // onSuccess()
          } catch (err) {
            if (err.code === 'auth/popup-closed-by-user') {
              setLoginPending(false)
            } else {
              // console.error('Google login err:', err.code)
            }
            setGoogleError(err)
          }
        }}
      >
        <Box
          component='img'
          src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'
          alt='Google logo'
          sx={{
            maxHeight: 18,
            mr: 1,
          }}
        />
        {signUp ? 'Sign Up with Google' : 'Sign In with Google'}
      </Button>
    </Box>
  )
}

const isQualifiedEmail = email => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

const LoginErrorDialog = ({ status, open, setOpen, setLoginPending }) => {
  const onClose = () => {
    setOpen(false)
    setLoginPending(false)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        id='login-error-title'
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <ErrorIcon color='error' sx={{ mr: 1 }} /> Login Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {status}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

const PasswordHelpDialog = ({ open, setOpen }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        Password Help
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant='h6'>
          Need help coming up with a good password? Try these tips!
        </DialogContentText>
        <ul>
          <li><DialogContentText>Make your password long.</DialogContentText></li>
          <li><DialogContentText>Try a combination of upper- and lower-case letters, numbers, and symbols.</DialogContentText></li>
          <li><DialogContentText>Never reuse your passwords.</DialogContentText></li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{'OK'}</Button>
      </DialogActions>
    </Dialog>
  )
}

const PasswordResetSentDialog = ({ open, setOpen, setSignInSignUp }) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
        setSignInSignUp(dialogStates.SIGN_IN)
      }}
    >
      <DialogTitle>
        Password Reset Sent
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          A password reset email has been sent. Remember to check your spam folder!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setOpen(false)
          setSignInSignUp(dialogStates.SIGN_IN)
        }}>
          {'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SignIn = ({ setLoginError, setSignInSignUp, setWindowTitle, setLoginPending }) => {
  const [, loginDispatch] = useLogin()
  const [email, setEmail] = useState(null)
  const [, setPassword] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [passwordError] = useState(false)
  const [googleError, setGoogleError] = useState(null)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (googleError) {
      const errMsg = authErrorToComponent(googleError.code)
      if (!errMsg) {
        setLoginPending(false)  // only on safe close!
        return
      }
      // console.error('Caught Google Login error!', googleError)
      setLoginError(authErrorToComponent(googleError.code))
    }
  }, [googleError, setLoginError, setLoginPending])

  useEffect(() => {
    if (email?.length > 2 && !isQualifiedEmail(email)) {
      setEmailError('Please enter a valid email address')
    } else {
      setEmailError(null)
    }
  }, [email])

  useEffect(() => setWindowTitle('Sign In'))

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    setLoginPending(true)
    try {
      await loginDispatch({
        type: LoginActions.EMAIL_LOGIN,
        email: data.get('email'),
        password: data.get('password'),
        shouldPersist: false,
      })
    } catch (e) {
      // console.log('Caught login error with code', e.code, 'and message', e.message)
      setLoginError(authErrorToComponent(e.code))
    }
  }

  return (
    <>
      <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1, width: ['fillContent', 420] }} novalidate>
        <TextField
          margin='normal'
          required
          fullWidth
          id='email'
          name='email'
          label='Email Address'
          type='email'
          autoComplete='email'
          autoFocus
          onChange={event => setEmail(event.target.value)}
          error={!!emailError}
          helperText={emailError}
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='password'
          label='Password'
          type={showPassword ? 'text' : 'password'}
          id='password'
          autoComplete='current-password'
          onChange={event => setPassword(event.target.value)}
          error={!!passwordError}
          helperText={passwordError}
        />
        <FormControlLabel
          control={<Checkbox
            checked={showPassword}
            onChange={event => setShowPassword(event.target.checked)}
            color='primary'
          />}
          label='Show Password'
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
      </Box>
      <Divider
        sx={{
          mt: 3,
          mb: 2,
        }}
        flexItem
      >
        <Chip label='OR' />
      </Divider>
      <GoogleLoginButton setGoogleError={setGoogleError} setLoginPending={setLoginPending} />
      <Divider
        sx={{
          mt: 3,
          mb: 2,
        }}
        flexItem
      />

      <Grid container>
        <Grid item xs>
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={(event) => {
              event.preventDefault()
              setSignInSignUp(dialogStates.FORGOT_PASSWORD)
            }}
            variant='body2'
          >
            Forgot password?
          </Link>
        </Grid>
        <Grid item xs>
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={(event) => {
              event.preventDefault()
              setSignInSignUp(dialogStates.SIGN_UP)
            }}
            variant='body2'
          >
            {'Don\'t have an account? Sign Up'}
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

const passwordScoreToStr = (score) => {
  if (score === 2) {
    return 'Medium'
  } else if (score === 3) {
    return 'High'
  } else if (score === 4) {
    return 'Very High'
  } else {
    return 'Low'
  }
}

const SignUp = ({ setLoginError, setSignInSignUp, setPasswordHelpOpen, setWindowTitle, setLoginPending }) => {
  const [, loginDispatch] = useLogin()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(false)
  const [passwordInfo, setPasswordInfo] = useState(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)
  const [googleError, setGoogleError] = useState(null)
  const [passwordScore, setPasswordScore] = useState(-1)
  const [isCommonPassword, setIsCommonPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => setWindowTitle('Sign Up'))

  useEffect(() => {
    if (googleError) {
      const errMsg = authErrorToComponent(googleError.code)
      if (!errMsg) {
        setLoginPending(false)  // only on safe close!
        return
      }
      // console.error('Caught Google Login error!', googleError)
      setLoginError(authErrorToComponent(googleError.code))
    }
  }, [googleError, setLoginError, setLoginPending])

  useEffect(() => {
    // Check if email is valid, and display error if not
    // Don't check if email length is too short to prevent some annoyance.
    if (email?.length > 2 && !isQualifiedEmail(email)) {
      setEmailError('Please enter a valid email address')
    } else {
      setEmailError(null)
    }
  }, [email])

  useEffect(() => {
    if (password.length > 1) {
      if (password.length < 8) {
        setPasswordInfo('At least 8 characters')
        setPasswordError(true)
      } else if (isCommonPassword) {
        setPasswordError(true)
        setPasswordInfo('Too Common')
      } else if (passwordScore >= 0) {
        // TODO determine correct score range for error
        setPasswordInfo('Strength: ' + passwordScoreToStr(passwordScore))
        if (passwordScore <= 1) {
          setPasswordError(true)
        } else {
          setPasswordError(false)
        }
      }
    } else {
      setPasswordError(false)
      setPasswordInfo('')
    }
  }, [password, passwordScore, isCommonPassword])

  useEffect(() => {
    import('zxcvbn').then((zxcvbn) => {
      const passwordInfo = zxcvbn.default(password)
      // console.log(passwordInfo)
      setPasswordScore(passwordInfo.score)
    })
    import('./commonPasswords').then((commonPasswords) => {
      if (commonPasswords.default.has(password)) {
        setIsCommonPassword(true)
      } else {
        setIsCommonPassword(false)
      }
    })
  }, [password])

  useEffect(() => {
    if (confirmPassword && password !== confirmPassword) {
      setConfirmPasswordError('Must match password')
    } else {
      setConfirmPasswordError('')
    }
  }, [password, confirmPassword])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoginPending(true)
    const data = new FormData(event.currentTarget)
    try {
      await loginDispatch({
        type: LoginActions.SIGNUP,
        email: data.get('email'),
        password: data.get('password'),
      })
    } catch (e) {
      setLoginError(authErrorToComponent(e.code))
    }
  }

  return (
    <>
      <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1, width: 420 }} novalidate>
        <TextField
          margin='normal'
          required
          fullWidth
          id='email'
          label='Email Address'
          name='email'
          autoComplete='email'
          autoFocus
          onChange={event => setEmail(event.target.value)}
          error={!!emailError}
          helperText={emailError}
        />
        <Box>
          <TextField
            margin='normal'
            required
            name='password'
            label='Password'
            type={showPassword ? 'text' : 'password'}
            id='password'
            autoComplete='new-password'
            onChange={event => setPassword(event.target.value)}
            error={!!passwordError}
            helperText={passwordInfo}
            sx={{
              flexDirection: 'column',
              maxWidth: '50%',
            }}
          />
          <TextField
            margin='normal'
            required
            name='repeatpassword'
            label='Repeat Password'
            type={showPassword ? 'text' : 'password'}
            id='repeatpassword'
            autoComplete='new-password'
            onChange={event => setConfirmPassword(event.target.value)}
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
            sx={{
              flexDirection: 'column',
              maxWidth: '50%',
            }}
          />
        </Box>
        <FormControlLabel
          control={<Checkbox
            checked={showPassword}
            onChange={event => setShowPassword(event.target.checked)}
            color='primary'
          />}
          label='Show Password'
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>
      </Box>

      {!!passwordError && <Link sx={{ cursor: 'pointer' }} onClick={() => setPasswordHelpOpen(true)}>Password Help</Link>}

      <Divider
        sx={{
          mt: 3,
          mb: 2,
        }}
        flexItem
      >
        <Chip label='OR' />
      </Divider>

      <GoogleLoginButton signUp setGoogleError={setGoogleError} />

      <Divider
        sx={{
          mt: 3,
          mb: 2,
        }}
        flexItem
      />

      <Grid container>
        <Grid item xs>
          <Link
            sx={{ cursor: 'pointer' }}
            variant='body2'
            onClick={(event) => {
              event.preventDefault()
              setSignInSignUp(dialogStates.SIGN_IN)
            }}
          >
            {'Already have an account? Sign In'}
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

const ForgotPassword = ({ setLoginError, setSignInSignUp, setWindowTitle, setPasswordResetSentOpen }) => {
  const [, loginDispatch] = useLogin()
  const [googleError, setGoogleError] = useState(null)

  useEffect(() => {
    if (googleError) {
      // console.error('Caught Google Login error!', googleError)
      setLoginError(authErrorToComponent(googleError.code))
    }
  }, [googleError, setLoginError])

  useEffect(() => setWindowTitle('Password Reset'))

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    try {
      await loginDispatch({
        type: LoginActions.FORGOT_PASSWORD,
        email: data.get('email'),
      })
      setPasswordResetSentOpen(true)  // TODO display a dialog first?
    } catch (e) {
      // console.log('Caught login error with code', e.code, 'and message', e.message)
      setLoginError(authErrorToComponent(e.code))
    }
  }

  return (
    <>
      <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1, width: 420 }} novalidate>
        <EmailTextField />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Send Password Reset Email
        </Button>
      </Box>
      <Divider
        sx={{
          mt: 3,
          mb: 2,
        }}
        flexItem
      >
        <Chip label='OR' />
      </Divider>
      <GoogleLoginButton setGoogleError={setGoogleError} />
      <Divider
        sx={{
          mt: 3,
          mb: 2,
        }}
        flexItem
      />

      <Grid container>
        <Grid item xs>
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={(event) => {
              event.preventDefault()
              setSignInSignUp(dialogStates.SIGN_IN)
            }}
            variant='body2'
          >
            Signing in?
          </Link>
        </Grid>
        <Grid item xs>
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={(event) => {
              event.preventDefault()
              setSignInSignUp(dialogStates.SIGN_UP)
            }}
            variant='body2'
          >
            {'Don\'t have an account? Sign Up'}
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

export default SignInSignUp
