import { Typography, Link } from '@mui/material'

const authErrorToComponent = (err) => {
  switch (err) {
    case 'auth/user-not-found':
      return <Typography>{'Could not find your account. Check your email address.'}</Typography>
    case 'auth/invalid-password':
    case 'auth/wrong-password':
      return <Typography>{'Incorrect password. Please check your password and try again.'}</Typography>
    case 'auth/email-already-exists':
    case 'auth/email-already-in-use':
      return <Typography>{'There is already an account with this email. Try logging in.'}</Typography>
    case 'auth/user-disabled':
      return <Typography>{'This account has been disabled. Contact '}<Link href={'mailto://help@firialabs.com'}>Firia</Link>{' for more information.'}</Typography>
    case 'auth/popup-closed-by-user':
      return null  // Not an issue, they just canceled the Google login
    case 'auth/invalid-email':
      return <Typography>{'Email address is invalid.'}</Typography>
    case 'auth/bad-func-call':
      return <Typography>{'There appears to be a problem with the network. Check your network connection and try again.'}</Typography>
    default:
      console.error(err)
      return <Typography>{'Unknown login error. Check your network connection and try again.'}</Typography>
  }
}

export default authErrorToComponent
