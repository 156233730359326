import fetchHandler from '../tracking/fetchHandler'
import { getUserSessionId } from './userAuthStore'
import { firebaseDomain } from '../domain'

const authFetchHandler = (url, options) => {
  const userSessionId = getUserSessionId()

  const headersClone = options?.headers ?? {}
  const headers = {...headersClone, Authorization: 'Bearer ' + userSessionId}
  return fetchHandler(`${firebaseDomain}/${url}`, {...options, headers})
}

export default authFetchHandler