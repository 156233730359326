import {
  Container,
  Paper,
  Typography,
  Link,
  Divider,
} from '@mui/material'

import LabsLogo from './LabsLogo'

const Copyright = (props) => {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {' '}
      <Link color='inherit' href='https://firialabs.com/'>
        Firia Labs
      </Link>
      {'.'}
    </Typography>
  )
}


const LoginParent = (props) => {
  const { title, containerRef } = props

  return (
    <Container component='main' maxWidth='md'>
      <Paper
        variant='outlined'
        sx={{
          mt: 8,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        ref={containerRef}
      >
        <LabsLogo />

        <Typography component='h1' variant='h5'>
          {title}
        </Typography>
        <Divider
          sx={{
            mt: 3,
            mb: 2,
          }}
          flexItem
        />
        {props.children}
      </Paper>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  )
}

export default LoginParent