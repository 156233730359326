var userSessionId
const getUserSessionId = () => userSessionId
const setUserSessionId = (newUserSessionId) => {
  userSessionId = newUserSessionId
}


var queuedUserSessionInfo
var userSessionInfoCb = () => {}
var cookieSessionIdStateOnSiteInitialization
var cookieSessionIdStateOnSiteInitializationCB = () => {}

var cbLoaded = false
const setUserAuthCallbacks = (sessionInfoCB, sessionIdStateOnSiteInitializationCB) => {
  userSessionInfoCb = sessionInfoCB
  if (queuedUserSessionInfo && !cbLoaded) {
    userSessionInfoCb(queuedUserSessionInfo)
  }

  cookieSessionIdStateOnSiteInitializationCB = sessionIdStateOnSiteInitializationCB
  if (cookieSessionIdStateOnSiteInitialization && !cbLoaded) {
    cookieSessionIdStateOnSiteInitializationCB()
  }

  cbLoaded = true
}


const setCookieSessionIdStateOnSiteInitialization = () => {
  if (!cbLoaded) {
    cookieSessionIdStateOnSiteInitialization = true
    return
  }
  cookieSessionIdStateOnSiteInitializationCB()
}

const hasCookieSessionIdInitializationCompleted = () => !!cookieSessionIdStateOnSiteInitialization

const setUserSessionInfo = (newUserSessionInfo) => {
  if (!cbLoaded) {
    queuedUserSessionInfo = newUserSessionInfo
    return
  }

  userSessionInfoCb(newUserSessionInfo)
}

const hasUserSessionInfoInitializationCompleted = () => {
  return queuedUserSessionInfo
}

export {
  setUserAuthCallbacks,
  getUserSessionId,
  setUserSessionId,
  setUserSessionInfo,
  setCookieSessionIdStateOnSiteInitialization,
  hasCookieSessionIdInitializationCompleted,
  hasUserSessionInfoInitializationCompleted,
}