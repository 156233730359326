import pino from 'pino'
import { sendRollbarError } from './rollbar'
// import { trackSmartlookMessage } from './smartlook'
import { storeLog } from './logStore'

const devMode = process.env.NODE_ENV === 'development'

const transmitSend = (_, { ts, level, messages, bindings }) => {
  if (level.label === 'info') {
    // messages.forEach((message) => {
    //   trackSmartlookMessage(message, { ts, level, bindings })
    // })
  }

  if (level.value > logger.levels.values.info) {
    messages.forEach((message) => {
      sendRollbarError(message)
    })

    if (devMode) {
      messages.forEach(m => console.error(`logger: (${level.label}) ${m}`))
    }
  }

  storeLog({ ts, level, messages, bindings })
}

const rootLogger = pino({
  level: 'info',
  browser: {
    serialize: !devMode,
    write: ()=>{},
    transmit: {
      send: transmitSend,
    },
  },
})

var logger = {...rootLogger}

const identifyLoggerUser = ({ id, username, email }) => {
  Object.assign(logger, rootLogger.child({ user: { id, username, email } }))
}

const clearLoggerUser = () => {
  Object.assign(logger, rootLogger)
}

export { identifyLoggerUser, clearLoggerUser }
export default logger