import { useEffect, useState } from 'react'

import {
  Button,
  Link,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material'

import { LoginActions, useLogin } from './LoginContext'

const EmailVerificationNotice = ({ sendVerification }) => {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography component='p'>{'Check your email for a message from us to activate your account.'}</Typography>
      <Typography component='p'>{' '}</Typography>
      <Box sx={{ mt: 1 }}>
        <Typography component='p'>{'Didn\'t get the email? Check your spam folder, and if it\'s not there,'}</Typography>
        <Typography component='p'><Link sx={{ cursor: 'pointer' }} onClick={sendVerification}>{'click here'}</Link>{' to try again.'}</Typography>
      </Box>
    </Box>
  )
}

const FiriaLinkButtons = () => {
  return (
    <Box
      sx={{
        mt: 3,
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-evenly',
        gap: 2,
      }}
    >
      <Button
        component={Link}
        href={'https://www.firialabs.com/'}
        variant='contained'
      >
        Firia Homepage
      </Button>
      <Button
        component={Link}
        href={'https://make.firialabs.com/'}
        variant='contained'
      >
        CodeSpace
      </Button>
      <Button
        component={Link}
        href={'https://license.firialabs.com/'}
        variant='contained'
      >
        License Portal
      </Button>
      <Button
        component={Link}
        href={'https://dashboard.firialabs.com/'}
        variant='contained'
      >
        Teacher Dashboard
      </Button>
    </Box>
  )
}

const SignOut = ({ setWindowTitle, redirect }) => {
  const [loginState, loginDispatch] = useLogin()
  const [emailVerified, setEmailVerified] = useState(loginState?.user?.emailVerified)

  const signOut = () => {
    loginDispatch({ type: LoginActions.LOGOUT })
  }
  useEffect(() => setEmailVerified(loginState?.user?.emailVerified), [loginState])
  useEffect(() => setWindowTitle('Signed In'), [setWindowTitle])

  return (
    <>
      <Typography component='p'>
        Signed in as <Link sx={{ cursor: 'pointer' }}>{loginState?.user?.email}</Link>.
      </Typography>
      {!emailVerified && <EmailVerificationNotice sendVerification={() => loginDispatch({ type: LoginActions.SEND_VERIFICATION })} />}
      {redirect ?
        <Button
          sx={{ mt: 3 }}
          component={Link}
          href={redirect}
          variant='contained'
        >
          Return to {redirect}
        </Button> :
        <FiriaLinkButtons />
      }
      <Button
        sx={{ mt: 3 }}
        variant='contained'
        onClick={signOut}
      >
        Sign Out
      </Button>
    </>
  )
}

const SignOutComponent = ({ setWindowTitle, redirect, willRedirect }) => {
  return (
    <>
      {!willRedirect ? <SignOut setWindowTitle={setWindowTitle} redirect={redirect} /> : <CircularProgress sx={{ margin: 8 }} />}
    </>
  )
}

export default SignOutComponent
