import Box from '@mui/material/Box'

import FiriaLabsLogo from './logo-min.png'

const LabsLogo = () => {
  return (
    <Box
      component='img'
      src={FiriaLabsLogo}
      alt='Firia Labs Logo'
      sx={{
        width: [325, 350],
        maxWidth: '350px',
        mb: 2,
      }}
    />
  )
}

export default LabsLogo
