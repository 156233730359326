import fetchHandler from '../../tools/tracking/fetchHandler'
import { firebaseDomain } from '../domain'
import { setUserSessionIdCookie, eraseUserSessionIdCookie } from './cookieOperations'
import handleLoginPortalRedirect from './handleLoginPortalRedirect'

const generateSessionIdWithTokenId = async (idToken) => {
  try {
    const resp = await fetchHandler(`${firebaseDomain}/getUserSessionCookieIdFromTokenId?idToken=${idToken}`, {
      method: 'GET',
    })

    if (resp.status === 200) {
      const message = (await resp.json()).message
      setUserSessionIdCookie(message)
      return message
    }
  } catch (err) {
    console.log(err)
    eraseUserSessionIdCookie()
  }
}

var generatingToken = false
const handleGenerateSessionIdWithTokenId = async (idToken) => {
  if (generatingToken) {
    return
  }
  generatingToken = true
  const generateSessionIdWithTokenIdSuccess = await generateSessionIdWithTokenId(idToken)
  generatingToken = false

  if (!generateSessionIdWithTokenIdSuccess) {
    return true
  }

  handleLoginPortalRedirect()
  return
}

export default handleGenerateSessionIdWithTokenId